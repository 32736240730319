import React, { useCallback, useEffect, useLayoutEffect, useState, useRef } from 'react';
import useIsScrolled from 'hooks/useIsScrolled';
import useDomElement from 'hooks/useDomElement';
import useBreakpointView from 'utils/useBreakpointView';
import throttle from 'utils/throttle';
import useExternalBanner from 'hooks/useExternalBanner';
import ContentfulCallToAction from 'components/Contentful/ContentfulCallToAction';
import chatBubbleIcon from './images/chat-bubble.svg';
import setActiveItemIndexAndScroll from './utils/setActiveItemIndexAndScroll';
import AnchorMenuLogo from './AnchorMenuLogo';
import AnchorMenu from './AnchorMenu';
import MobileCallToAction from './AnchorMenuMobileCta';
import { AnchorMenuHeader, Container, InnerContainer } from './styles';

const AnchorLinkNavigation = ({
  anchorMenuDriftInteractionId,
  navigation,
  contextualCta,
  inline,
  isHeroDark,
  hideAnchorMenuChatWithUs,
  alwaysShowInlineAnchorMenu,
  ...otherProps
}) => {
  const [wrapperHeight, setWrapperHeight] = useState('100%');
  const [isSticky, setIsSticky] = useState(false);
  const [navPosition, setNavPosition] = useState(0);
  const [activeIndex, setActiveIndex] = useState(-1);
  const { isScrolled } = useIsScrolled(100);
  const { isScrolled: isStickyScroll } = useIsScrolled(30);
  const bannerHeight = useExternalBanner();
  const containerRef = useRef();
  const isMobile = useBreakpointView(['xs', 'sm']);
  const isXsScreen = useBreakpointView(['xs']);
  const isDesktop = !isMobile;
  const headerEl = useDomElement('#header');
  const breakpointIdentified = isMobile !== null;
  const displayAnchorMenuCta = contextualCta && isDesktop && breakpointIdentified && isScrolled;
  const displayMobileCta = contextualCta && isMobile && isSticky && breakpointIdentified;
  const driftIcon = useDomElement('#drift-frame-controller');
  const desktopInline = alwaysShowInlineAnchorMenu && inline;
  const [headerBottom, setHeaderBottom] = useState(0);

  useLayoutEffect(() => {
    const cleanup = () => {};
    if (headerEl && inline) {
      const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.contentRect && entry.contentRect.bottom !== headerBottom) {
            setHeaderBottom(entry.contentRect.bottom);
          }
        });
      });
      resizeObserver.observe(headerEl);
      return () => resizeObserver.disconnect();
    }
    return cleanup;
  }, [headerEl, inline, headerBottom]);

  useLayoutEffect(() => {
    const height = document.getElementById(`section-${otherProps?.contentful_id}`)?.offsetHeight;
    setWrapperHeight(height > 0 ? height : '100%');
  }, [otherProps?.contentful_id]);

  useLayoutEffect(() => {
    if (driftIcon && isXsScreen) {
      driftIcon.style.right = '5px';
      driftIcon.style.bottom = isSticky ? '100px' : '5px';
      driftIcon.style.transition = 'bottom 0.3s ease-out';
    }
  }, [driftIcon, isXsScreen, isSticky]);

  useLayoutEffect(() => {
    if (containerRef?.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const navigationPosition = containerRect.top + window.scrollY;
      setNavPosition(navigationPosition);
      const headerElement = document.querySelector('header'); // Adjust as needed
      if (headerElement) {
        const headerRect = headerElement.getBoundingClientRect();
        setHeaderBottom(headerRect.bottom);
      }
    }
  }, []);

  const handleScroll = useCallback(
    throttle(() => {
      requestAnimationFrame(() => {
        setActiveItemIndexAndScroll(navigation, setActiveIndex, headerBottom + wrapperHeight);
        const scrollPosition = window.pageYOffset;
        const isNavSticky = scrollPosition > navPosition - headerBottom;
        setIsSticky(isNavSticky);
      });
    }, 100),
    [navPosition, headerBottom],
  );

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.addEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    handleScroll();
  }, [isStickyScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerBottom, navPosition, handleScroll]);

  const handleDriftClick = () => {
    if (window.drift) {
      window.drift.api.startInteraction({
        interactionId: parseInt(anchorMenuDriftInteractionId) || 285462,
        replaceActiveConversation: false,
      });
    }
  };

  return (
    <div className="flex items-center justify-center" style={{ height: wrapperHeight }}>
      {!inline && breakpointIdentified && (
        <AnchorMenuHeader top={headerBottom}>
          <>
            <AnchorMenuLogo isHeroDark={isHeroDark} isScrolled={isScrolled} />
            {!hideAnchorMenuChatWithUs && (
              <button
                type="button"
                className="in-page-subnav--contextualCta-drift"
                onClick={() => handleDriftClick()}
              >
                <img src={chatBubbleIcon} alt="chat" />
                {!isXsScreen && <p>Chat with us</p>}
              </button>
            )}
          </>
        </AnchorMenuHeader>
      )}
      {(isScrolled || isMobile || desktopInline) && navigation && (
        <Container
          id="anchor-menu-container"
          isDesktop={desktopInline ? false : isDesktop}
          isMobile={isMobile}
          isScrolled={isScrolled}
          isSticky={isSticky}
          ref={containerRef}
          bannerHeight={bannerHeight + headerBottom}
          inline={inline}
        >
          <InnerContainer
            $afterFirst={activeIndex > 0}
            isMobile={isMobile}
            isScrolled={isScrolled}
            $beforeLast={activeIndex < navigation?.navigation?.length - 1}
            desktopInline={desktopInline}
          >
            <AnchorMenu
              activeIndex={activeIndex}
              cta={contextualCta}
              isSticky={isSticky}
              navigation={navigation}
              isMobile={isMobile}
              desktopInline={desktopInline}
            />

            {displayAnchorMenuCta && (
              <div className="in-page-subnav--contextualCta">
                <ContentfulCallToAction {...contextualCta[0]} />
              </div>
            )}
          </InnerContainer>
        </Container>
      )}
      {displayMobileCta && <MobileCallToAction cta={contextualCta} />}
    </div>
  );
};

export default AnchorLinkNavigation;
